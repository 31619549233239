<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">RPageModal</h2>
      <r-third-level-menu />
    </template>
    <template #action-bar>
      <div class="level">
        <div class="level-left">
          <b-button
            tag="router-link"
            class="is-command"
            icon-left="account-plus"
            to="/test/r-page-modal/create"
          >
            Modal Example
          </b-button>
        </div>
      </div>
    </template>
    <template #page-content>
      <r-card>
        <b-table
          :data="tableData"
          paginated
          order="is-centered"
          :columns="columns"
          :per-page="paginationData.per_page"
          :total="paginationData.total"
          :current-page="paginationData.current_page"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
        >
          <template #bottom-left>
            <div class="level-left">
              Displaying data from 1 to 10 of
              {{ paginationData.total }} items
            </div>
          </template>
        </b-table>
      </r-card>
      <router-view></router-view>
    </template>
    <template #right-sidebar>
      <r-right-sidebar v-model="activeSidebar">
        <r-right-sidebar-item v-if="activeSidebar === 0">
          <span @click="activeSidebar = 1">to sidebar 2</span>
        </r-right-sidebar-item>
        <r-right-sidebar-item v-else-if="activeSidebar === 1">
          sidebar 2
        </r-right-sidebar-item>
      </r-right-sidebar>
    </template>
  </r-page>
</template>
<script>
export default {
  data() {
    return {
      activeSidebar: null,
      dateFirst: new Date('11-05-1992'),
      checkbox: false,
      paginationData: {
        total: 10,
        current_page: 1,
        per_page: 5,
      },
      filter: null,
      tableData: [
        {
          id: 1,
          first_name: 'Jesse',
          last_name: 'Simmons',
          date: '2016-10-15 13:43:27',
          gender: 'Male',
        },
        {
          id: 1,
          first_name: 'Jesse',
          last_name: 'Simmons',
          date: '2016-10-15 13:43:27',
          gender: 'Male',
        },
        {
          id: 1,
          first_name: 'Jesse',
          last_name: 'Simmons',
          date: '2016-10-15 13:43:27',
          gender: 'Male',
        },
        {
          id: 1,
          first_name: 'Jesse',
          last_name: 'Simmons',
          date: '2016-10-15 13:43:27',
          gender: 'Male',
        },
        {
          id: 2,
          first_name: 'John',
          last_name: 'Jacobs',
          date: '2016-12-15 06:00:53',
          gender: 'Male',
        },
        {
          id: 3,
          first_name: 'Tina',
          last_name: 'Gilbert',
          date: '2016-04-26 06:26:28',
          gender: 'Female',
        },
        {
          id: 4,
          first_name: 'Clarence',
          last_name: 'Flores',
          date: '2016-04-10 10:28:46',
          gender: 'Male',
        },
        {
          id: 5,
          first_name: 'Anne',
          last_name: 'Lee',
          date: '2016-12-06 14:38:38',
          gender: 'Female',
        },
      ],
      columns: [
        {
          field: 'id',
          label: 'ID',
          width: '40',
          numeric: true,
        },
        {
          field: 'first_name',
          label: 'First Name',
        },
        {
          field: 'last_name',
          label: 'Last Name',
        },
        {
          field: 'date',
          label: 'Date',
          centered: true,
        },
        {
          field: 'gender',
          label: 'Gender',
        },
      ],
    }
  },
}
</script>
